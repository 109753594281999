// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "regenerator-runtime/runtime";
import "picturefill";
import Vue from 'vue/dist/vue.esm.js';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { EventBus } from '../utils/event-bus';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';

import VScrollLock from 'v-scroll-lock'
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
})

// Register Angolia Search Component
import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

// Social Sharing
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

if (!window.location.hostname == "localhost" && !window.location.hostname == "127.0.0.1") {
  Sentry.init({
    dsn: 'https://e6c72a61e28e4bdfbb96b01a39533f3d:728c8fb660c741b4b8933c0044a9ea62@o44241.ingest.sentry.io/5261870',
    integrations: [new VueIntegration({Vue, attachProps: true})],
  });
}

// Register all Components Globally
const modules = import.meta.glob('../components/**/*.vue')

Object.entries(modules).forEach(([path, module]) => {
  const componentName = path.split('/').pop().replace(/\.\w+$/, '')
  
  // Register component with lazy loading
  Vue.component(componentName, () => module())
})

// Register the App
document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '[data-behavior="vue"]',
    // apolloProvider,
  });
})

document.addEventListener('DOMContentLoaded', function() {
  const eventBus = EventBus;
  const body = document.querySelector('body');
  const header = document.querySelector('header');
  const footer = document.querySelector('footer');
  const navToggles = document.querySelectorAll('.nav-toggle');
  const nav = document.querySelector('.mobile-nav');
  const modal = document.querySelector('.modal');
  const reportDownloadButton = document.querySelector('.m-news__single-article__report__download');
  const reportLeadCaptureButton = document.querySelector('.m-news__single-article__report__leadcapture');
  const ctaButton = document.querySelector('.cta-bar');
  const ctaModule = document.querySelector('.cta-module');
  const firstModule = document.querySelector('main section:not(.s-header-spacer)');
  let navOpen = false;

  eventBus.$on('bodyNoScroll', (payload) => {
    if(payload.scroll == 'false') {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
  });

  const toggleReportPrompt = function(visibility) {
    eventBus.$emit('toggleReportPrompt', {visibility: visibility});
  }
  const toggleCtaPrompt = function(visibility) {
    eventBus.$emit('toggleCtaPrompt', {visibility: visibility});
  }

  if(ctaButton) {

    let ctaIsModuleVisible;
    let isFirstModuleVisible;

    function ctaCheckForVisibility() {

      if(ctaIsModuleVisible || isFirstModuleVisible) {
        toggleCtaPrompt('hide');
      } else {
        toggleCtaPrompt('show');
      }
      
    }

    const ctaObserverOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }

    const ctaObserver = new IntersectionObserver(function(entries) {
      entries.forEach(entry => {        
        if(entry.target.dataset.obsid == 'cta-module') {
          ctaIsModuleVisible = entry.isIntersecting;
        }
        if(entry.target.attributes.id == firstModule.attributes.id) {
          isFirstModuleVisible = entry.isIntersecting;
        }
        ctaCheckForVisibility();
      });
    }, ctaObserverOptions);

    ctaObserver.observe(ctaModule);
    ctaObserver.observe(firstModule);
  }

  if(reportDownloadButton) {
    if (reportLeadCaptureButton) {
      reportLeadCaptureButton.addEventListener('click', function() {
        eventBus.$emit('showReportDownloadForm');
        eventBus.$emit('toggleModal', {visibility: 'show'});
        eventBus.$emit('bodyNoScroll', {scroll: 'false'});
      });
    }

    let lastKnownButtonEntry;
    let lastKnownFooterEntry;
    let isButtonBelowViewport;

    function checkForVisibility() {
      if(isButtonBelowViewport) {
        toggleReportPrompt('hide');
      } else {
        if(!lastKnownButtonEntry) {
          toggleReportPrompt('show');
        } else {
          toggleReportPrompt('hide');
        }
        eventBus.$emit('toggleReportPromptSticky', !lastKnownButtonEntry && !!lastKnownFooterEntry);          
      }
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }

    const observer = new IntersectionObserver(function(entries) {
      entries.forEach(entry => {
        if(entry.target.dataset.obsid == 'download') {
          if(entry.boundingClientRect.top > entry.rootBounds.bottom) {
            isButtonBelowViewport = true;
          } else {
            isButtonBelowViewport = false;
          }
        }
        
        if(entry.target.dataset.obsid == 'download') {
          lastKnownButtonEntry = entry.isIntersecting;
        } else if(entry.target.dataset.obsid == 'footer') {
          lastKnownFooterEntry = entry.isIntersecting;
        }
        checkForVisibility();
      });
    }, observerOptions);

    observer.observe(reportDownloadButton);
    observer.observe(footer);
  }
  

  navToggles && navToggles.forEach(toggle => {
    toggle.addEventListener('click', function() {
      if(!navOpen) {
        this.classList.add('is-active');
        const navToggleIcon = document.querySelector('.nav-toggle-icon');
        navToggleIcon && navToggleIcon.classList.add('is-active');
        nav.classList.add('is-visible');
        modal && modal.classList.add('is-visible');
        body.classList.add('no-scroll');
        header.classList.add('mobile-nav-active');
        navOpen = true;
      } else {
        this.classList.remove('is-active');
        const navToggleIcon = document.querySelector('.nav-toggle-icon');
        navToggleIcon && navToggleIcon.classList.remove('is-active');
        nav.classList.remove('is-visible');
        modal && modal.classList.remove('is-visible');
        body.classList.remove('no-scroll');
        header.classList.remove('mobile-nav-active');
        navOpen = false;
      }
    });
  })

  // Slide Toggle
  const SlideToggleSections = Array.from(document.querySelectorAll('.slide-toggle'));

  SlideToggleSections.forEach((section, index) => {

    section.querySelector(".slide-toggle__trigger").addEventListener('click', function() {
      section.classList.toggle('is-active');
      let active = section.classList.contains('is-active');

      section.querySelectorAll(".slide-toggle__toggleable").forEach(t => {
        t.classList.toggle('is-active', active);
        const heightSize = active ? t.scrollHeight : 0
    
        t.style.overflow = 'hidden';
        t.style.transitionProperty = 'height';
        t.style.height = `${heightSize}px`;
        t.style.transitionDuration = '300ms';  
      })
    })
  })

  // Mobile nav sections
  const mobileNavSections = Array.from(document.querySelectorAll('.mobile-nav__section--collapsible'));

  function closeAll() {
    mobileNavSections.forEach(section => {
      section.querySelectorAll(".mobile-nav__section__content").forEach(t => {
        t.style.overflow = 'hidden';
        t.style.height = `${t.scrollHeight}px`; // cancel set to auto but specific value for transition
        setTimeout(() => t.style.height = '0px', 10)
      })                  
    });
  }

  // function setMobileNavHeights() {
  //   mobileNavSections.forEach((section, index) => {
  //     let content = section.lastElementChild;
  //     if(!(content.dataset.mheight && content.dataset.mheight > 0)) {
  //       content.dataset.mheight = content.scrollHeight
  //     }
  //   })
  // }

  // setMobileNavHeights()

  // window.addEventListener('resize', setMobileNavHeights);

  mobileNavSections.forEach((section, index) => {
    let content = section.lastElementChild;
    let contentHeight = content.scrollHeight;

    section.querySelector(".mobile-nav__section__trigger").addEventListener('click', function(e) {
      section.classList.toggle('is-active');
      let isOpen = section.classList.contains('is-active');
      
      if(!isOpen) {
        // closeAll();
      }

      section.querySelectorAll(".mobile-nav__section__content").forEach(t => {
        const heightSize = isOpen ? t.scrollHeight : 0
    
        t.style.overflow = 'hidden';
        t.style.transitionProperty = 'height';
        t.style.transitionDuration = '300ms';  
        if (isOpen) {
          t.style.height = `${t.scrollHeight}px`;
          setTimeout(() => t.style.height = 'auto', 300)
        } else {
          t.style.height = `${t.scrollHeight}px`; // cancel set to auto but specific value for transition
          setTimeout(() => t.style.height = '0px', 10)
        }
      })            
    });
  });

  // Careers page video overlay
  const videoOverlay = document.querySelector('.s-careers-intro__video__overlay');
  let overlayVisible = true;

  videoOverlay && videoOverlay.addEventListener('click', function() {
    if(overlayVisible) {
      this.classList.add('is-hidden');
      overlayVisible = false;
    } else {
      this.classList.remove('is-hidden');
      overlayVisible = true;
    }
  });


  // Content Long Form Collapsible Structures
  function collapseSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;
    
    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';
    
    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we 
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;
      
      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function() {
        element.style.height = 0 + 'px';
      });
    });
  }
  
  function expandSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;
    
    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';
  
    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', function transitionExpandFinished(e) {
      // remove this event listener so it only gets triggered once
      element.removeEventListener('transitionend', transitionExpandFinished);
      
      // remove "height" from the element's inline styles, so it can return to its initial value
      element.style.height = null;
    });
  }

  const collapsibles = Array.from(document.querySelectorAll('.collapsible'));
  collapsibles.forEach((collapsible, index) => {
    var trigger = collapsible.querySelector('.collapsible--trigger');
    var section = collapsible.querySelector('.collapsible--content');
    var arrow = collapsible.querySelector('.arrow');

    trigger.addEventListener('click', function(e) {
      var isCollapsed = collapsible.getAttribute('data-collapsed') === 'true';
      if(isCollapsed) {
        expandSection(section)
        arrow.classList.remove('down')
        collapsible.setAttribute('data-collapsed', 'false')
      } else {
        collapseSection(section)
        arrow.classList.add('down')
        collapsible.setAttribute('data-collapsed', 'true')
      }
    });
  });


  // Ga Event Tracking
  const gaEnabled = document.querySelectorAll('[data-ga-enable="true"]');

  gaEnabled.forEach((element, index) => {
    element.addEventListener('click', function(e) {
      var category = element.getAttribute('data-ga-category');
      var action = element.getAttribute('data-ga-action');
      var label = element.getAttribute('data-ga-label');
    
      // if(category && action && label) {
      gtag('event', 'GAEvent', {
        'event_category': category,
        'event_action': action,
        'event_label': label
      });        
      // }
    });
  });

});


// Allow for auto-clicking of elements on page load, i.e. for cookie settings page
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-auto-click="true"]').forEach((element, index) => {
    setTimeout(() => element.click(), 1000);
  });
})
